<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Unggah <b>Layanan</b></div>
    </b-alert>

    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <!-- section notes -->
        <div class="card mb-2">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <h5>Aturan file unggahan :</h5>
                <ul>
                  <li>
                    File harus berformat
                    <span class="text-success">Excel</span> (xlxs atau csv)
                  </li>
                  <li>
                    File harus memiliki ukuran maksimal 4 Mb atau dibawahnya
                  </li>
                  <li>
                    File harus mengandung kolom dengan nama atau struktur
                    seperti dibawah ini <b>(tidak harus berurutan)</b>
                  </li>
                  <li>
                    Kolom <b>Komisi</b> merupakan kolom yang berisi besaran
                    komisi pegawai
                  </li>
                </ul>
              </div>

              <div class="col-md-12">
                <table
                  class="table table-bordered"
                  style="border: 1px solid black"
                >
                  <tr>
                    <th>nama</th>
                    <th>harga</th>
                    <th>kategori</th>
                    <th>komisi</th>
                  </tr>
                  <tr>
                    <td>Layanan 1</td>
                    <td>1.000.000</td>
                    <td>kategori 1</td>
                    <td>50.000</td>
                  </tr>
                  <tr>
                    <td>Layanan 2</td>
                    <td>500.000</td>
                    <td>Kategori 2</td>
                    <td>100.000</td>
                  </tr>
                  <tr>
                    <td>Layanan 3</td>
                    <td>50.000</td>
                    <td>Kategori 3</td>
                    <td>5.000</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- form -->
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <input
            type="file"
            ref="uploadFile"
            name="file"
            id="file"
            style="display: none"
            @change="onFileUpload"
          />

          <!-- choose file section -->
          <div class="card my-2" v-if="isUpload == false">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-3">
                  <div
                    class="uploaded-box rounded text-center"
                    @click="$refs.uploadFile.click()"
                  >
                    <i
                      class="fas fa-folder-open fa-7x d-block"
                      style="color: #245590"
                    ></i>
                    <h5 class="d-block mt-4">Pilih File</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <span class="text-danger d-block">{{ error.nama }}</span>
                  <span class="text-danger d-block">{{ error.komisi }}</span>
                  <span class="text-danger d-block">{{ error.harga }}</span>
                  <span class="text-danger d-block">{{ error.kategori }}</span>
                  <span class="text-danger d-block">{{ error.file }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- show uploaded file -->
          <div class="card my-2" v-if="isUpload">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-3">
                  <div class="uploaded-box rounded text-center">
                    <div class="d-block">
                      <div class="d-flex justify-content-end">
                        <button
                          @click="resetFile"
                          type="button"
                          class="
                            btn
                            rounded-circle
                            btn-outline-danger
                            text-center
                            px-3
                            py-2
                          "
                        >
                          <i class="fas fa-times p-0"></i>
                        </button>
                      </div>
                    </div>
                    <i
                      class="fas fa-file-excel fa-7x d-block"
                      style="color: #245590"
                    ></i>
                    <h5 class="d-block mt-4">{{ form.file_name }}</h5>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-3">
                  <button
                    type="submit"
                    class="btn w-100 mt-1"
                    style="background-color: #245590; color: white"
                  >
                    Unggah & Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Card from "@/view/content/Card.vue";
import Form from "@/component/masters/payment-categories/Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      form: {
        file: "",
        file_name: "",
      },

      isUpload: false,
      isValidate: false,

      error: {
        nama: "",
        komisi: "",
        harga: "",
        kategori: "",
        file: "",
      },
    };
  },

  methods: {
    onFileUpload(evt) {
      this.form.file = evt.target.files[0];
      this.form.file_name = this.form.file.name;
      this.isUpload = true;
    },

    resetFile() {
      this.$refs.uploadFile.value = null;
      this.isUpload = false;
    },

    async formOnSubmit() {
      this.isValidate = false;
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, "/payment-categories/excel");
      // Check Response
      if (response.state == "error") {
        this.resetFile();
        this.isValidate = true;

        console.log(response);
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/payment-categories");
      }
    },
  },

  components: {
    Card,
    Form,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Layanan", route: "" },
      { title: "Layanan", route: "/payment-categories/list" },
      { title: "Tambah" },
    ]);
  },
};
</script>

<style>
.uploaded-box {
  border: 1px solid #245590;
  padding: 10px;
  color: #245590 !important;
  cursor: pointer;
}
</style>