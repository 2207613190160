<template>
  <div>
    <b-modal
      id="modal-inventory-payment-categories"
      size="xl"
      title="Pilih Inventori"
      hide-footer
    >
      <div class="row">
        <div class="col-md-9">
          <Table
            purpose="modal"
            modalPurpose="modal"
            :selectedItem="selectedItem"
            :checkbox="checkbox"
            :isSortcut="isSortcut"
            :isAbjad="true"
            @chosenItem="chosenItem"
            @packageMedicine="packageMedicine"
            :secondPurpose="secondPurpose"
          />
        </div>
        <div class="col-md-3">
          <b-table
            striped
            hover
            :items="selectedItem"
            :fields="fields"
            responsive
          >
            <!-- :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '' , width: field.key === 'responsible_doctor' ? '10%' : '' }" -->
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'actions'
                      ? purpose == 'master'
                        ? '17%'
                        : ''
                      : '',
                  width: field.key == 'generic_name' ? '15%' : '',
                }"
              />
            </template>
            <template #cell(name)="data">
              <span>{{ data.item.name }}</span>
            </template>
            <template #cell(actions)="data">
              <b-button
                size="sm"
                class="btn-danger mr-1"
                style="padding: 5px !important"
                v-b-tooltip.hover
                title="Hapus"
                @click="deleteData(data.item.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/inventory/TableMultiple.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isSortcut: Boolean,
    secondPurpose: {
      type: String,
      default: "listing",
    },
    purpose: {
      type: String,
      default: "master",
    },
  },

  data() {
    return {
      selectedData: [],
      // fields
      fields: [
        {
          key: "name",
          label: "Inventori",
          sortable: true,
          thClass: "nowrap",
          tdClass: "p-1 nowrap",
        },
        {
          key: "actions",
          label: "",
          thClass: "nowrap",
          tdClass: "p-1",
        },
      ],
    };
  },

  components: {
    Table,
  },

  methods: {
    async deleteData(id) {
      let result = await module.deleteNoFire(
        "payment-category-inventories/" + id
      );

      if (result == true) {
        this.$root.$emit("updateDataInvetoryItem");
      }
    },

    chosenItem(value) {
      this.$emit("chosenItem", value);
    },

    packageMedicine(datas) {
      this.$emit("packageMedicine", datas);
      this.selectedItem = datas;
    },
  },
  mounted() {},
};
</script>

<style>
</style>